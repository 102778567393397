<template>
  <div class="app-input" :style="{ '--input-hover-color': `var(--${color})` }">
    <label v-if="label" :for="name" class="app-input-label" :class="labelClass">
      <span>{{ label }}</span>
      <!-- Required Asterisk -->
      <span v-if="isRequired" class="text-danger">*</span>
    </label>
    <div class="app-input-container">
      <!-- Prepend Icon -->
      <div class="app-input-icon-prepend">
        <slot name="prepend-icon">
          <AppIcon v-if="prependIcon" :name="prependIcon"></AppIcon>
        </slot>
      </div>
      <!-- Input Element -->
      <div class="app-input-number w-100" @blur="loseFocus" v-if="type === 'number'">
        <input
          ref="app-input-number"
          v-model="innerVal"
          :type="type"
          v-bind="$attrs"
          :name="name"
          @focus="focus = true"
          v-on="$listeners"
        />
        <div class="icons-container">
          <AppIcon @click="add" class="number add" name="caret_up"></AppIcon>
          <AppIcon @click="subtract" class="number minus" name="caret_down"></AppIcon>
        </div>
      </div>
      <!-- Input Element -->
      <input
        v-else
        v-model="innerVal"
        :type="type === 'password' && visible ? 'text' : type"
        v-bind="$attrs"
        :name="name"
        @focus="focus = true"
        @blur="loseFocus"
        v-on="$listeners"
      />
      <!-- Form Validation Input (maintain app-form-validation class) -->
      <input
        class="app-form-validation"
        type="hidden"
        :value="allValidationsPassed"
      />
      <!-- Append Icon -->
      <div class="app-input-icon-prepend">
        <slot name="append-icon">
          <AppIcon v-if="appendIcon" :name="appendIcon"></AppIcon>
          <AppIcon
            v-else-if="type === 'password'"
            hoverable
            :name="visible ? 'visible_off' : 'visible'"
            @click="visible = !visible"
          ></AppIcon>
        </slot>
      </div>
    </div>
    <!-- Select Options -->
    <transition name="app-input-select-options">
      <ul
        v-show="focus"
        class="app-input-select-options mt-n3 bg-white shadow-40 w-100"
      >
        <li
          v-for="(item, index) in filteredItems || autosuggestItem"
          :key="`app-input-select-option-${index}-${item}`"
          class="p-1 pl-3 pr-3"
          @click="innerVal = item"
          @keypress="innerVal = item"
        >
          <span class="pt-1 pb-1 d-inline-block">{{ item }}</span>
        </li>
      </ul>
    </transition>
    <!-- Error Message -->
    <span v-if="!hideError" class="app-input-error">
      {{ error }}
    </span>
  </div>
</template>

<script>
import _appDefaultInput from '@/shared/mixins/_appDefaultInput';
import _appErrorValidation from '@/shared/mixins/_appErrorValidation';
import AppIcon from './AppIcon.vue';
export default {
  name: 'AppInput',

  components: { AppIcon },

  mixins: [_appDefaultInput, _appErrorValidation],

  props: {
    type: { type: String, default: 'text' },
    name: { type: String, default: '' },
    label: { type: String, default: '' },
    prependIcon: { type: String, default: '' },
    appendIcon: { type: String, default: '' },
    color: { type: String, default: 'primary' },
    labelClass: { type: String, default: '' },
    hideError: { type: Boolean, default: false },
    validations: { type: Array, default: () => [] },
    validate: { type: [Number, String, Boolean], default: false },
    autosuggestItem: { type: [Boolean, Array], default: false },
  },

  data() {
    return {
      focus: false,
      visible: false,
    };
  },

  computed: {
    filteredItems() {
      const searchFilter = this.innerVal;
      if (searchFilter && this.autosuggestItem.length > 0) {
        const newItems = this.autosuggestItem.filter((value) => {
          const item = value.text || value.value || value;
          return item
            .toString()
            .toLowerCase()
            .includes(searchFilter.toLowerCase());
        });
        return newItems;
      } else {
        return null;
      }
    },
  },

  methods: {
    loseFocus() {
      if (this.autosuggestItem.length > 0) {
        setTimeout(() => {
          this.focus = false;
        }, 100);
      } else {
        this.focus = false;
      }
    },

    add() { 
      this.innerVal++;
      this.focus = true;
    },

    subtract() {
      if (Number(this.innerVal) <= 1) {
        return;
      }

      this.innerVal--;
      this.focus = true;
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_inputs';
</style>
